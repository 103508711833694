import Vue from 'vue';

export default {
  get: async () => Vue.http.get('general/push')
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`general/push/${id}`)
    .then((res) => res.json()),

  count: async (data) => Vue.http.post('general/push/count', data)
    .then((res) => res.json()),

  countFromSQL: async (id) => Vue.http.get(`general/push/${id}`)
    .then((res) => res.json()),

  sent: async (id) => Vue.http.post(`general/push/send/${id}`)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`general/push/${id}`)
    .then((res) => res.json()),

  upload: async (model) => Vue.http.post('general/push/upload', model)
    .then((res) => res.json()),

  save: async (model) => Vue.http.post('general/push', model)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`general/push/${id}`, model)
    .then((res) => res.json()),
};
