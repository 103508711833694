<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          Push Notification
        </h3>

        <router-link :to="{ name: 'general-push-create' }">
          <b-button size="sm" class="ml-3" variant="success">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-plus-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5
                4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5
                0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
              />
            </svg>

            {{ $t('buttons.create') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col lg="8" class="mb-sm-2">
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="$t('inputs.typeSearch')"
          />

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              {{ $t('buttons.clear') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :filter="filter"
      :busy="loading"
      :items="list"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
      :emptyFilteredText="$t('messages.emptyFilteredTable')"
    >
      <template v-slot:table-busy>
        <div
          class="
            text-center text-danger
            my-2
            align-items-center
            d-flex
            flex-column
          "
        >
          <b-spinner
            class="align-middle flex-direction-column mb-3"
          ></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(title)="data">
        <p class="m-0">
          <strong>{{ $t('pushNotification.titlePT') }}</strong>
          {{ data.item.titlePT }}

          <br />
          <br />

          <strong>{{ $t('pushNotification.titleEN') }}</strong>
          {{ data.item.titleEN }}
        </p>
      </template>

      <template v-slot:cell(message)="data">
        <p class="m-0" style="max-width: 500px">
          <strong>{{ $t('pushNotification.messagePT') }}</strong>
          {{ data.item.messagePT }}

          <br />
          <br />

          <strong>{{ $t('pushNotification.messageEN') }}</strong>
          {{ data.item.messageEN }}
        </p>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="text-center">
          <b-button
            size="sm"
            variant="success"
            class="mb-1"
            v-if="!data.item.isProcessed"
            @click="onConfirmSent(data.item)"
          >
            {{ $t('pushNotification.sendPush') }}
          </b-button>

          <p v-if="!!data.item.isProcessed" class="mb-1">
            {{ $t('pushNotification.sentIn') }}
            <br />{{ formatDate(data.item.scheduledTo) }}
          </p>

          <b-button
            size="sm"
            variant="info"
            class="mb-1"
            v-if="!data.item.isProcessed"
            @click="
              $router.push({
                name: 'general-push-edit',
                params: { id: data.item.id }
              })
            "
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>

            {{ $t('buttons.edit') }}
          </b-button>

          <template v-if="!data.item.isProcessed">
            <br />

            <b-button
              size="sm"
              variant="danger"
              :disabled="data.item.isProcessed"
              @click="onConfirmDelete(data.item)"
            >
              <b-icon icon="trash" />
              {{ $t('buttons.delete') }}
            </b-button>
          </template>
        </div>
      </template>
    </b-table>

    <b-modal
      centered
      v-model="sentModal"
      @ok="onSent"
      :ok-title="$t('buttons.ok').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">
        {{ $t('pushNotification.sentNotificationMessage') }}
        <br />
        {{ $t('pushNotification.continue') }}
      </div>
    </b-modal>

    <b-modal
      centered
      v-model="deleteModal"
      @ok="onDelete"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">{{ $t('messages.deleteConfirmMessage') }}</div>
    </b-modal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import GeneralPush from '../../../../services/GeneralPush';

export default {
  data() {
    const data = {
      list: [],
      filter: '',

      loading: true,
      sentModal: false,
      deleteModal: false,

      pushId: null,

      fields: [
        {
          key: 'title',
          label: this.$t('inputs.title'),
          sortable: true,
        },

        {
          key: 'message',
          label: this.$t('inputs.message'),
          sortable: true,
        },

        { key: 'type', label: this.$t('inputs.type'), sortable: true },

        {
          key: 'isProcessed',
          label: this.$t('inputs.sent'),
          sortable: true,
          formatter: (val) => (val ? 'Sim' : 'Não'),
        },

        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.list = await GeneralPush.get();
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },

    async onConfirmSent({ id }) {
      try {
        this.pushId = id;
        this.sentModal = true;
      } catch (e) {
        Sentry.captureException(e);
      }
    },

    async onConfirmDelete({ id }) {
      try {
        this.pushId = id;
        this.deleteModal = true;
      } catch (e) {
        Sentry.captureException(e);
      }
    },

    async onDelete() {
      try {
        this.loading = true;

        await GeneralPush.delete(this.pushId);

        this.pushId = null;
        this.deleteModal = false;
        this.onLoad();
      } catch (e) {
        Sentry.captureException(e);
      }
    },

    async onSent() {
      try {
        this.loading = true;

        await GeneralPush.sent(this.pushId);

        this.pushId = null;
        this.sentModal = false;
        this.onLoad();
      } catch (e) {
        console.log('Errro', e);
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },

    formatDate(dt) {
      try {
        return DateTime.fromISO(dt).setLocale(this.$i18n.locale).toFormat('ff');
      } catch (e) {
        return dt;
      }
    },
  },
};
</script>
